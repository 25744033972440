import "./style.less";
import {
  Image,
  Checkbox,
  Modal,
  Input,
  Spin,
  message,
  Button,
  Space,
} from "antd";
import { PhoneOutlined } from "@ant-design/icons";
import store, { setAaStatus } from "../../store";
import emitter from "../../utils/emitter";
import { useSelector } from "react-redux";
import { callStatusZh, sipStatusZh } from "../../utils/call-status-zh";
import ReactDOM from "react-dom";
import Dials from "../dials";
import Callin from "../callin";
import sipHelper from "../../utils/sip-helper";
import React, { useState, useRef, useEffect, useCallback } from "react";
import axios from "axios";
// import jsonpAdapter from "axios-jsonp";

function CallHeader() {
  const callStatus = useSelector((state) => state.callStatus);
  const fsStatus = useSelector((state) => state.fsStatus);
  const holdStatus = useSelector((state) => state.holdStatus);

  const extenRef = useRef();
  const passwdRef = useRef();
  const hostRef = useRef();
  // 创建一个 ref 来引用 <audio> 元素
  const audioRef = useRef(null);
  const antWebRTCAA = localStorage.getItem("antWebRTCAA");

  const [errMsg, setErrMsg] = useState("");
  const [companyid, setCompanyid] = useState("");
  const [jnum, setJnum] = useState("");
  const [exten, setExten] = useState("");
  const [phonenum, setPhonenum] = useState("");
  const [userdata, setUserdata] = useState("");
  //   const [isLoading, setIsLoading] = useState(false);
  const [initialOK, setInitialOK] = useState(false);
  const [isHangUp, setIsHangUp] = useState(false);

  const [dialErr, setDialErr] = useState(false);
  const [dialStatus, setDialStatus] = useState("");

  const [loadingModalShow, setLoadingModalShow] = useState(false);
  const [devices, setDevices] = useState([]);
  const devicesRef = useRef(devices); // 用 ref 持久化 devices 的最新值

  const [completionStatus, setCompletionStatus] = useState("");
  const [duringTime, setDuringTime] = useState(0); // 计时器秒数
  const [isTiming, setIsTiming] = useState(false); // 是否正在计时
  const intervalRef = useRef(null); // 用于存储计时器引用

  const [deviceType, setDeviceType] = useState(""); // 是否正在计时

  useEffect(() => {
    // 选中
    localStorage.setItem("antWebRTCAA", true);
    store.dispatch(setAaStatus(true));

    // 获取音频输出设备列表
    navigator.mediaDevices.enumerateDevices().then((deviceInfos) => {
      console.log("deviceInfos", deviceInfos);
      const audioOutputDevices = deviceInfos.filter(
        (device) => device.kind === "audiooutput"
      );
      console.log("audioOutputDevices", audioOutputDevices);
      setDevices(audioOutputDevices);
      devicesRef.current = audioOutputDevices; // 更新 ref 中的值
    });

    // 初始化微信配置
    window.wx.config({
      debug: false, // 开启调试模式
      appId: "wx17c90da3b7ded2e7", // 必填，公众号的唯一标识
      timestamp: "yourTimestamp", // 必填，生成签名的时间戳
      nonceStr: "yourNonceStr", // 必填，生成签名的随机串
      signature: "yourSignature", // 必填，签名
      jsApiList: ["checkJsApi", "navigateBack"], // 必填，需要使用的JS接口列表
    });

    window.wx.error((res) => {
      // 配置失败后的回调
      console.error("微信JSSDK配置错误: ", res);
    });

    // setIsLoading(true);
    setLoadingModalShow(true);
    const urlParams = new URLSearchParams(window.location.search);
    const p_companyid = urlParams.get("companyid");
    const p_jnum = urlParams.get("jnum");
    let extenNum = urlParams.get("exten");
    extenNum = extenNum % 1000 === 0 ? "1001" : extenNum; //分机号 如果是1000的整数倍分机号  改为1001
    const p_exten = extenNum;
    const p_phonenum = urlParams.get("phonenum");
    const p_userdata = urlParams.get("userdata");

    setCompanyid(p_companyid);
    setJnum(p_jnum);
    setExten(p_exten);
    setPhonenum(p_phonenum);
    setUserdata(p_userdata);
    if (!p_companyid || !p_jnum || !p_exten || !p_phonenum) {
      //   setErrMsg("未正确传入参数，请检查！");
      message.error("未正确传入参数，请检查！");
    } else {
      //   setErrMsg("正在登录");
      //   message.success("正在登录");

      localStorage.setItem("antWebRTCexten", p_exten);
      localStorage.setItem("antWebRTCpasswd", "uqQklR0N");
      localStorage.setItem("antWebRTChost", "phone3.jieyihealth.com");

      callAction("fsLogin");
      const deviceType = getDeviceType();
      setDeviceType(deviceType);
      setInitialOK(true);
      if (deviceType !== "iOS" && deviceType !== "MacSafari") {
        setTimeout(() => {
          sipHelper.setAudioElement(deviceType); // 设置 audio 元素
        }, 500);
        setTimeout(() => {
          setDialStatus("正在呼叫");
          // setErrMsg("正在呼叫");
          // console.log("正在呼叫");
          // sipHelper.call(p_phonenum);
          doDial(p_companyid, p_jnum, p_exten, p_phonenum, p_userdata);
        }, 1000);
      }
    }
  }, []);

  useEffect(() => {
    if (completionStatus === "accepted") {
      setIsTiming(true);
    } else if (completionStatus === "ended") {
      setIsTiming(false);
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }

      console.log("devices", devices);

      console.log("devicesRef", devicesRef.current); // 使用 ref 获取最新的设备列表
      setDialStatus("通话结束");
      setIsHangUp(true);
      setTimeout(() => {
        console.log("window.wx.miniProgram", window.wx.miniProgram);
        window.wx.miniProgram.getEnv((res) => {
          console.log("window.wx.miniProgram.getEnv res", res);
          if (res.miniprogram) {
            //退出小程序
            handleNavigateBack();
          } else {
            console.log("不在微信小程序环境中");
          }
        });
      }, 1000);
      try {
        handleNavigateBack();
      } catch (e) {
        console.log(e);
      }
    }
  }, [completionStatus]);

  useEffect(() => {
    if (isTiming) {
      intervalRef.current = setInterval(() => {
        setDuringTime((prevTime) => prevTime + 1);
      }, 1000);

      return () => {
        clearInterval(intervalRef.current);
      };
    }
  }, [isTiming]);

  const handleCallCompletion = (msg) => {
    setCompletionStatus(msg);
    console.log("handleCallCompletion", msg);
  };
  const handleDeviceChange = async (deviceId) => {
    const audioElement = document.getElementById("audio");
    if (audioElement && audioElement.setSinkId) {
      try {
        await audioElement.setSinkId(deviceId);
        console.log(`音频设备已切换到: ${deviceId}`);
      } catch (error) {
        console.error("设置音频设备失败:", error);
      }
    } else {
      console.warn("当前浏览器不支持 setSinkId");
    }
  };
  const handleNavigateBack = () => {
    if (window.wx && window.wx.miniProgram) {
      window.wx.miniProgram.navigateBack({
        delta: 1, // 返回的层级
      });
    }
  };
  const getDeviceType = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    const platform = navigator.platform;

    // Check for iOS
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }

    // Check for Android
    if (/android/i.test(userAgent)) {
      return "Android";
    }

    // Check for Mac Safari
    if (
      platform === "MacIntel" &&
      /Safari/.test(userAgent) &&
      !/Chrome/.test(userAgent)
    ) {
      return "MacSafari";
    }

    // Default to other devices
    return "Other";
  };

  const doDial = async (
    p_companyid,
    p_jnum,
    p_exten,
    p_phonenum,
    p_userdata
  ) => {
    const params = {
      action: "SendDial",
      companyid: p_companyid,
      auth: "42025b68f33611ec88cc000c29973e94",
      exten: p_exten,
      phonenum: p_phonenum,
      userdata: p_userdata,
    };
    try {
      //axios get 方式
      const response = await axios.get(
        "https://phone3.jieyihealth.com:9501/interface.php",
        {
          params: params,
          headers: {},
        }
      );

      const result = response.data; // axios 解析 JSON 响应
      console.log("post doDial result", result);
      if (result.errno === "0") {
        setDialErr(false);
        setDialStatus(
          result.message === "呼叫成功" ? "通话中" : result.message
        );
      } else {
        setDialErr(true);
        setDialStatus(result.message);
      }
    } catch (error) {
      setDialErr(true);
      setDialStatus("发生错误");
      setIsHangUp(true);
      console.error("请求出错:", error);
    }
  };
  const hangUp = () => {
    console.log("click hangUp");
    callAction("hangUp");
  };

  const handlePlay = () => {
    if (dialStatus === "通话中") {
      hangUp();
      return;
    }

    const urlParams = new URLSearchParams(window.location.search);
    const p_companyid = urlParams.get("companyid");
    const p_jnum = urlParams.get("jnum");
    let extenNum = urlParams.get("exten");
    extenNum = extenNum % 1000 === 0 ? "1001" : extenNum; //分机号 如果是1000的整数倍分机号  改为1001
    const p_exten = extenNum;
    const p_phonenum = urlParams.get("phonenum");
    const p_userdata = urlParams.get("userdata");

    setCompanyid(p_companyid);
    setJnum(p_jnum);
    setExten(p_exten);
    setPhonenum(p_phonenum);
    setUserdata(p_userdata);
    if (!p_companyid || !p_jnum || !p_exten || !p_phonenum) {
      //   setErrMsg("未正确传入参数，请检查！");
      message.error("未正确传入参数，请检查。");
    } else {
      setDialStatus("正在呼叫");
      // setErrMsg("正在呼叫");
      // console.log("正在呼叫");
      // sipHelper.call(p_phonenum);

      setTimeout(() => {
        console.log("设置 audio 元素");
        sipHelper.setAudioElement(deviceType); // 设置 audio 元素
      }, 500);
      setTimeout(() => {
        console.log("开始拨号");
        doDial(p_companyid, p_jnum, p_exten, p_phonenum, p_userdata);
      }, 1000);
    }
  };

  const callAction = (action) => {
    if (action === "fsLogin") {
      if (fsStatus === "registered") {
        sipHelper.logout();
      } else {
        if (fsStatus === "") {
          sipHelper.login(handleCallCompletion);
        }
      }
    } else if (action === "call") {
      emitter.setDialsVisible.call(true);
    } else if (action === "hangUp") {
      sipHelper.hangUp();
    } else if (action === "hold") {
      sipHelper.hold();
    } else if (action === "unhold") {
      sipHelper.unhold();
    } else if (action === "setting") {
      emitter.setDialsVisible.call(true);
    }
  };
  const onChange = (e) => {
    localStorage.setItem("antWebRTCAA", e.target.checked);
    store.dispatch(setAaStatus(e.target.checked));
  };
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    let exten = extenRef.current.state.value;
    let passwd = passwdRef.current.state.value;
    let host = hostRef.current.state.value;
    console.log(exten, passwd, host);
    localStorage.setItem("antWebRTCexten", exten);
    localStorage.setItem("antWebRTCpasswd", passwd);
    localStorage.setItem("antWebRTChost", host);
    if (fsStatus === "registered") {
      sipHelper.logout();
    }
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
      2,
      "0"
    )}`;
  };
  return (
    <>
      <div className="call-header" style={{ display: "none" }}>
        <div
          className="call-header-item fslogin"
          onClick={() => {
            callAction("fsLogin");
          }}
        >
          <div className="call-header-item-icon">
            <Image
              preview={false}
              width={26}
              height={26}
              src={require("../../assets/call-header/fslogin.png").default}
            />
          </div>
          <div className="call-header-item-txt">
            {fsStatus
              ? "[" +
                localStorage.getItem("antWebRTCexten") +
                "]" +
                sipStatusZh[fsStatus]
              : "登录"}
          </div>
        </div>
        <div
          className="call-header-calltool"
          style={{
            opacity: fsStatus === "registered" ? 1 : 0.2,
            pointerEvents: fsStatus === "registered" ? "auto" : "none",
          }}
        >
          {callStatus === "confirmed" ||
          callStatus === "accepted" ||
          callStatus === "calling" ? (
            <>
              <div
                className="call-header-item"
                onClick={() => {
                  callAction("hangUp");
                }}
              >
                <div className="call-header-item-icon">
                  <Image
                    preview={false}
                    width={30}
                    src={require("../../assets/call-header/hangup.png").default}
                  />
                </div>
                <div className="call-header-item-txt">挂断</div>
              </div>

              {holdStatus.local || holdStatus.remote ? (
                <div
                  className="call-header-item"
                  onClick={() => {
                    callAction("unhold");
                  }}
                >
                  <div className="call-header-item-icon">
                    <Image
                      preview={false}
                      width={28}
                      src={
                        require("../../assets/call-header/unhold.png").default
                      }
                    />
                  </div>
                  <div className="call-header-item-txt">继续</div>
                </div>
              ) : (
                <div
                  className="call-header-item"
                  onClick={() => {
                    callAction("hold");
                  }}
                >
                  <div className="call-header-item-icon">
                    <Image
                      preview={false}
                      width={28}
                      src={require("../../assets/call-header/hold.png").default}
                    />
                  </div>
                  <div className="call-header-item-txt">暂停</div>
                </div>
              )}
            </>
          ) : (
            <div
              className="call-header-item"
              onClick={() => {
                callAction("call");
              }}
            >
              <div className="call-header-item-icon">
                <Image
                  preview={false}
                  width={30}
                  src={require("../../assets/call-header/call.png").default}
                />
              </div>
              <div className="call-header-item-txt">拨打</div>
            </div>
          )}

          <div className="call-header-item" style={{ width: 100 }}>
            <div className="call-header-item-icon">
              {callStatus === "confirmed" || callStatus === "accepted" ? (
                <Image
                  preview={false}
                  width={30}
                  src={
                    require("../../assets/call-header/tonghuazhong.gif").default
                  }
                />
              ) : (
                <Image
                  preview={false}
                  width={30}
                  src={require("../../assets/call-header/kefu.png").default}
                />
              )}
            </div>
            <div className="call-header-item-txt">
              {callStatus ? callStatusZh[callStatus] : "空闲中"}{" "}
              {(holdStatus.remote || holdStatus.local) && "--暂停中"}
            </div>
          </div>

          <div className="call-header-item">
            <div className="call-header-item-icon">
              <Checkbox
                onChange={onChange}
                disabled
                defaultChecked={antWebRTCAA === "true" ? true : false}
              ></Checkbox>
            </div>
            <div className="call-header-item-txt">自动接听 </div>
          </div>
        </div>
        <div className="call-header-item" onClick={showModal}>
          <div className="call-header-item-icon">
            <Image
              preview={false}
              width={30}
              src={require("../../assets/call-header/setting.png").default}
            />
          </div>
          <div className="call-header-item-txt">设置</div>
        </div>
      </div>

      {ReactDOM.createPortal(<Dials />, document.body)}
      {ReactDOM.createPortal(<Callin />, document.body)}
      <Modal
        title="分机配置"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Input
          placeholder="分机号"
          allowClear
          ref={extenRef}
          defaultValue={localStorage.getItem("antWebRTCexten")}
        />
        <Input
          placeholder="注册密码"
          allowClear
          ref={passwdRef}
          defaultValue={localStorage.getItem("antWebRTCpasswd")}
        />
        <Input
          placeholder="注册地址"
          allowClear
          ref={hostRef}
          defaultValue={localStorage.getItem("antWebRTChost")}
        />
      </Modal>
      <div
        className="accu"
        style={{
          display: initialOK ? "flex" : "none",
        }}
      >
        <div className="phone-container">
          <div className="phone-number">{phonenum}</div>
          <div className="completion-status">{formatTime(duringTime)}</div>
          <div
            className="call-status"
            style={{ color: dialErr ? "red" : "white" }}
          >
            {dialStatus}
          </div>
          <div className="button-container">
            {/* <Space.Compact block> */}
            {/* <Button.Group> */}
            {deviceType !== "iOS" && deviceType !== "MacSafari" ? (
              <Button type="primary" onClick={hangUp} block danger>
                {isHangUp ? "已挂断" : "挂断"}
              </Button>
            ) : (
              <Button
                type="primary"
                onClick={handlePlay}
                block
                disabled={isHangUp}
              >
                {dialStatus === ""
                  ? "开始呼叫"
                  : dialStatus === "通话中"
                  ? "挂断"
                  : "已挂断"}
              </Button>
            )}
            {/* 用 ref 引用动态创建的 audio 元素 */}
            <div style={{ display: "none" }}>
              <audio ref={audioRef} controls id="audio_react">
                {/* <source src="your-audio-file.mp3" type="audio/mp3" /> */}
                Your browser does not support the audio tag.
              </audio>
            </div>
            {/*{devicesRef.current.map((device) => {
                return (
                  <Button
                    onClick={() => {
                      handleDeviceChange(device.deviceId);
                    }}
                  >
                    {device.label || "默认设备"}
                  </Button>
                );
              })}*/}
            {/* </Button.Group> */}
            {/* </Space.Compact> */}

            {/* <Space.Compact block>
              <Button type="primary">Button 1</Button>
              <Button type="primary">Button 2</Button>
              <Button type="primary">Button 3</Button>
              <Button type="primary">Button 4</Button>
            </Space.Compact> */}
          </div>
          {/* <div style={{ margin: "20px", wordBreak: "break-all" }}>
            {JSON.stringify(devicesRef.current)}
          </div> */}
        </div>
      </div>
    </>
  );
}
export default CallHeader;
