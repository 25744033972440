import JsSIP from "jssip";
import store, { setCallStatus, setFsStatus, setHoldStatus } from "../store";
import emitter from "../utils/emitter";
const log = console.log;

class SipHelper {
  static instance;
  coolPhone;
  session;
  player = null;
  constructor(opts = {}) {
    // // this.player = document.createElement("audio");
    // // this.player = document.getElementsByTagName("audio")[0];
    // this.player = document.getElementById("audio_react");
    // // this.player.style.display = "block";
    // this.player.autoplay = true;
    // this.player.muted = false;
    // this.player 不会在构造函数中初始化，需要在组件挂载后调用 setAudioElement
  }

  setAudioElement(msg) {
    console.error("setAudioElement set!, msg is: ", msg);
    this.player = document.getElementById("audio_react");
    if (this.player) {
      this.player.style.display = "block";
      this.player.autoplay = true;
      this.player.muted = false;

      // const playPromise = this.player.play();
      // if (playPromise !== undefined) {
      //   playPromise
      //     .then(() => {
      //       console.log("Audio is playing after user interaction.");
      //     })
      //     .catch((error) => {
      //       console.error("Error playing audio:", error);
      //     });
      // }
    } else {
      console.error("Audio element not found!");
    }
  }

  /**
   * 单例
   */
  static getInstance() {
    if (!SipHelper.instance) {
      SipHelper.instance = new SipHelper();
    }
    return SipHelper.instance;
  }
  callback() {}
  /**
   * 电话断掉后，需要初始化的数据或者执行的方法
   */
  init() {
    this.session = null; // 会话id清空
    store.dispatch(setHoldStatus({ local: false, remote: false })); // 全局状态 挂起初始化
    setTimeout(() => {
      store.dispatch(setCallStatus("")); // 电话状态初始化
    }, 1000);
    // //既然是微信的ready事件，只能在微信端使用
    // document.addEventListener("WeixinJSBridgeReady", function () {
    //   this.player.play();
    // }, false);

    // setTimeout(function () {
    //   window.wx &&
    //     window.wx.ready(() => {
    //       // JSSDK配置成功后的回调
    //       this.player.play();
    //     });
    //   document.addEventListener("touchstart", () => {
    //     this.player.play();
    //   });
    // }, 2000);
  }

  /**
   * 分机注册，登录
   * @param {*} phoneNumber 分机号码
   */
  login(callback) {
    if (callback) {
      this.callback = callback;
    }
    store.dispatch(setFsStatus("loging"));
    let phone = localStorage.getItem("antWebRTCexten");
    let passwd = localStorage.getItem("antWebRTCpasswd");
    let host = localStorage.getItem("antWebRTChost");
    //创建一个 JsSIP User Agent
    const wsUri = "wss://" + host + ":8089/ws"; // Fs的 ws协议地址
    const sipPwd = passwd; // FS密码
    const sipUri = "sip:" + phone + "@" + host + ";transport=wss"; // 分机号注册地址 格式 sip: + 分机号码 + @ + AST注册地址
    const contact_uri = "sip:" + phone + "@" + host + ""; // 分机号注册地址 格式 sip: + 分机号码 + @ + AST注册地址
    const configuration = {
      sockets: [new JsSIP.WebSocketInterface(wsUri)],
      uri: sipUri,
      password: sipPwd,
      contact_uri: contact_uri,
      session_timers: true, // 启用会话计时器（根据RFC 4028)
      user_agent: "Ant WebRTC v1.0",
      realm: "asterisk",
      //outbound_proxy_set: wsUri,
      display_name: String(phone),
      // autostart: true,   // 自动连接
      // register: true, // 自动就绪
    };
    this.coolPhone = new JsSIP.UA(configuration);

    // Starting the User Agent
    this.setUAEvent();
    this.coolPhone.start();
  }

  peerConnectionEventHandler(evt) {
    log("======peerconnection", evt);
    console.log(
      "peerConnectionEventHandler this.player",
      !!this.player,
      this.player
    );
    // 确保 this.player 已经被初始化
    if (!this.player) {
      console.warn("Player is not initialized, trying to set audio element.");
      this.setAudioElement(); // 确保音频元素已被设置
    }

    // 检查是否成功获取了音频元素
    if (!!this.player) {
      console.warn("evt", evt);

      evt.peerconnection.ontrack = (evt) => {
        console.warn("ontrack", evt);

        // Safari 和现代浏览器兼容：通过 evt.streams[0] 获取媒体流
        const stream = evt.streams[0];
        if (!stream) {
          console.error("无法获取媒体流");
          return;
        }

        const theTracks = stream.getTracks();
        console.warn("onTrack theTracks", theTracks);

        // 设置 srcObject 并播放
        this.player.srcObject = stream;
        this.player.autoplay = true;

        // Safari 可能会延迟加载元数据，因此使用 onloadedmetadata 确保元数据加载完毕后再播放
        this.player.onloadedmetadata = () => {
          try {
            this.player.currentTime = 0; // 重置播放时间
            this.player.play().catch((e) => console.error("播放失败: ", e)); // 播放失败捕获
          } catch (e) {
            console.error("播放加载错误: ", e);
          }
        };
      };
    } else {
      console.error("Failed to set audio element: Player is still null.");
    }
  }
  /**
   * 绑定ua事件
   * @param {*} ua
   */
  setUAEvent(ua) {
    // ws 开始尝试连接
    this.coolPhone.on("connecting", (args) => {
      log("ws尝试连接");
      if (this.callback) {
        this.callback("connecting");
      }
    });

    // ws 连接完毕
    this.coolPhone.on("connected", () => {
      log("ws连接完毕");
      if (this.callback) {
        this.callback("connected");
      }
    });

    // ws 连接失败
    this.coolPhone.on("disconnected", () => {
      log("ws连接失败");
      if (this.callback) {
        this.callback("disconnected");
      }
    });

    // SIP 注册成功
    this.coolPhone.on("registered", (e) => {
      log("SIP已注册");
      if (this.callback) {
        this.callback("registered");
      }
      store.dispatch(setFsStatus("registered"));
    });

    //  SIP 注册失败
    this.coolPhone.on("registrationFailed", (e) => {
      if (this.callback) {
        this.callback("registrationFailed");
      }
      log("SIP注册失败");
      store.dispatch(setFsStatus("registrationFailed"));
      setTimeout(() => {
        store.dispatch(setFsStatus(""));
      }, 1000);
    });

    // SIP 取消注册
    this.coolPhone.on("unregistered", (e) => {
      if (this.callback) {
        this.callback("unregistered");
      }
      log("SIP主动取消注册或注册后定期重新注册失败");
      store.dispatch(setFsStatus("unregistered"));
      setTimeout(() => {
        store.dispatch(setFsStatus(""));
      }, 1000);
    });

    // IM消息 事件
    this.coolPhone.on("newMessage", (e) => log("im新消息事件"));

    // 来电或者外呼事件
    this.coolPhone.on("newRTCSession", (e) => {
      if (this.callback) {
        this.callback("newRTCSession");
      }
      log(`新的${e.originator === "local" ? "外呼" : "来电"}`, e);
      //JsSIP.debug.enable('JsSIP:*');
      //JsSIP.debug.disable('JsSIP:*');
      const session = e.session;
      this.session = session;
      const peerconnection = session.connection;
      if (e.originator === "local") {
        console.log(JsSIP.version);

        peerconnection.addEventListener("addstream", (event) => {
          // const audio = document.querySelector('audio');
          // const stream = new MediaStream();
          // var theTracks = evt.stream;//.getTracks();
          // stream.addTrack(theTracks)
          // log("onAddStream theTracks", theTracks);
          this.player.srcObject = event.stream;
          // if (theTracks) {
          //   this.player.srcObject = stream;
          // }
          try {
            this.player.load();
            this.player.currentTime = 0;
            this.player.play();
          } catch (e) {
            console.log("load e", e);
          }
          // //既然是微信的ready事件，只能在微信端使用
          // document.addEventListener("WeixinJSBridgeReady", function () {
          //   this.player.play();
          // }, false);
          //	debugger;
        });
      } else {
        //const aaStatus = useSelector(state => state.aaStatus);
        //log('自动接听：',store.getState().aaStatus);
        session.on("peerconnection", (evt) => {
          this.peerConnectionEventHandler(evt);
        });
        const callers = session.remote_identity.uri.user;
        //let antWebRTCAA=localStorage.getItem("antWebRTCAA");
        log("自动接听1：", localStorage.getItem("antWebRTCAA"));
        emitter.setCallinStatus.call(
          true,
          callers,
          localStorage.getItem("antWebRTCAA")
        );
        //emitter.setCallinStatus.call(true, callers,store.getState().aaStatus);
      }
      e.session.on("progress", function (data) {
        if (this.callback) {
          this.callback("progress");
        }
        log("来电了progress");
        /* var audio = document.createElement('audio');
                audio.setAttribute("id", "ringId");
                audio.setAttribute("src", "https://192.168.1.18/sounds/ring.wav");
                audio.setAttribute('autoplay', 'autoplay');
                audio.play();
                */
      });
      // 接听失败
      session.on("failed", (mdata) => {
        if (this.callback) {
          this.callback("failed");
        }
        emitter.setCallinStatus.call(false);
        store.dispatch(setCallStatus("failed"));
        this.init();
        log("来电的时候 拒接或者 还没接听对方自己就挂断了");
      });

      // 接听成功
      session.on("accepted", (response, cause) => {
        if (this.callback) {
          this.callback("accepted");
        }
        log("接听成功accepted!");
        emitter.setCallinStatus.call(false);
        store.dispatch(setCallStatus("accepted"));
      });

      // 接听成功后 挂断
      session.on("ended", () => {
        if (this.callback) {
          this.callback("ended");
        }
        log("接听结束");
        store.dispatch(setCallStatus("ended"));
        this.init();
      });

      // 通话被挂起
      session.on("hold", (data) => {
        if (this.callback) {
          this.callback("hold");
        }
        const org = data.originator;
        if (org === "local") {
          log("通话被本地挂起:", org);
          store.dispatch(setHoldStatus({ local: true }));
        } else {
          store.dispatch(setHoldStatus({ remote: true }));
          log("通话被远程挂起:", org);
        }
      });

      // 通话被继续
      session.on("unhold", (data) => {
        if (this.callback) {
          this.callback("unhold");
        }
        const org = data.originator;
        if (org === "local") {
          log("通话被本地继续:", org);
          store.dispatch(setHoldStatus({ local: false }));
        } else {
          log("通话被远程继续:", org);
          store.dispatch(setHoldStatus({ remote: false }));
        }
      });
    });
  }

  /**
   * 登出
   */
  logout() {
    this.coolPhone.unregister(); // 注销
    this.coolPhone.stop({ register: true });
    store.dispatch(setFsStatus(""));
  }

  /**
   * 拨打
   * @param {*} phoneNumber 拨打号码
   */
  call(phoneNumber) {
    const options = {
      eventHandlers: {
        progress(e) {
          log("正在呼叫:", e);
          store.dispatch(setCallStatus("calling"));
        },
        failed(e) {
          log("呼叫失败: ", e);
          store.dispatch(setCallStatus("callFailed"));
          setTimeout(() => {
            store.dispatch(setCallStatus(""));
          }, 1000);
        },
        ended(e) {
          log(
            "呼叫结束:" + e.originator === "remote" ? "对方挂断" : "自己挂断",
            e
          );
          store.dispatch(setCallStatus("callEnded"));
          setTimeout(() => {
            store.dispatch(setCallStatus(""));
          }, 1000);
        },
        confirmed(e) {
          log(
            "呼叫接受" + e.originator === "remote"
              ? "自己已接受"
              : "对方已接受",
            e
          );
          store.dispatch(setCallStatus("confirmed"));
        },
      },
      mediaConstraints: { audio: true, video: false },
      pcConfig: {
        iceServers: [],
        rtcpMuxPolicy: "negotiate",
      },
    };
    this.coolPhone.call(`sip:${phoneNumber}`, options);
  }

  /**
   * 接听
   */
  answer() {
    log("接听成功answer");
    this.session.answer({
      mediaConstraints: { audio: true, video: false },
      pcConfig: {
        iceServers: [],
        rtcpMuxPolicy: "negotiate",
      },
    });
  }

  /**
   * 挂断
   */
  hangUp() {
    if (this.session && this.session.isEnded() === false) {
      this.session.terminate();
    }
    this.session = null;
  }

  /**
   * 挂起
   */
  hold() {
    this.session.hold({ useUpdate: false });
  }

  /**
   * 继续
   */
  unhold() {
    this.session.unhold({ useUpdate: false });
  }
}
export default SipHelper.getInstance();
